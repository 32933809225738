<template>
  <div>
    <v-card>
      <v-care-title>
        <p class="text-h6 pa-5">
          {{ skuStock.productName }} {{ skuStock.title !== null ? '/' :'' }} {{ skuStock.title }}
        </p>
      </v-care-title>
      <v-card-text>
        <v-form ref="skuLogForm">
          <v-row>
            <v-col class="d-flex flex-row-reverse pb-0">
              <div class="d-flex align-center">
                <v-btn
                  class="me-3"
                  @click="resetLogSearch()"
                >
                  <span>重置</span>
                </v-btn>
              </div>
              <div class="d-flex align-center">
                <v-btn
                  color="secondary"
                  class="me-3"
                  @click="toLogSearch()"
                >
                  <span>查询</span>
                </v-btn>
              </div>

              <div
                v-if="$can('product_list_search', 'product')"
                class="d-flex align-center pt-6 mr-5 pb-0"
              >
                <DateTimePick
                  v-model="logSearch.endTime"
                  label="结束时间"
                />
              </div>
              <div
                v-if="$can('product_list_search', 'product')"
                class="d-flex align-center pt-6 mr-5 pb-0"
              >
                <DateTimePick
                  v-model="logSearch.startTime"
                  label="开始时间"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col>
            <v-data-table
              :footer-props="{
                itemsPerPageAllText: '全部',
                itemsPerPageText: '每页显示 ',
                showCurrentPage: true
              }"
              disable-filtering
              :headers="getLogHeader()"
              :items="stockLogs"
              :items-per-page="logPages.itemsPerPage"
              :server-items-length="logPages.serverItemsLength"
              no-data-text="无数据"
              @pagination="loadLogPage"
            >
              <template #[`item.productName`]="{ item }">
                <v-row>
                  <v-col cols="auto">
                    <v-avatar size="33">
                      <img
                        :src="item.productImg"
                        alt="John"
                      >
                    </v-avatar>
                  </v-col>
                  <v-col class="pl-0">
                    <span class="text-subtitle-2">{{ item.productName }}  </span><br>
                    <span
                      v-if="!item.isSingle"
                      class="text-caption"
                    >{{ item.skuName }}</span>
                  </v-col>
                </v-row>
              </template>
              <template #[`item.categoryName`]="{ item }">
                <v-row>
                  <v-col class="pl-0">
                    <span class="text-subtitle-2">{{ item.categoryName }}  </span><br>
                    <span
                      class="text-caption"
                    >{{ item.warehouseName }}</span>
                  </v-col>
                </v-row>
              </template>
              <template #[`item.typeName`]="{ item }">
                <span style="color:blueviolet">{{ item.typeName }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import StockLogApi from '@/api/product/stockLog'
import DateTimePick from '@/components/DateTimePicker'

export default {
  name: 'Log',
  components: {
    DateTimePick,
  },
  props: {
    skuStock: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      logPages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      stockLogs: [],
      logSearch: {},
    }
  },
  mounted() {
  },
  methods: {
    getLogHeader() {
      const baseHeader = [
        // { text: 'ID', sortable: false, value: 'id' },
        { text: '商品名称', value: 'productName' },
        { text: '所属分类/仓库', value: 'categoryName' },
        { text: '类型', value: 'typeName' },
        { text: '时间', value: 'logTime' },
        { text: '入库数', value: 'inNum' },
        { text: '出库数', value: 'outNum' },
        { text: '库存', value: 'stockNum' },
        { text: '操作人', value: 'creator' },
      ]

      return baseHeader
    },
    loadLogPage({ page, itemsPerPage }) {
      let rows = itemsPerPage
      if (!rows) {
        rows = this.logPages.itemsPerPage
      }

      const searchData = {
        rows,
        page,
        categoryId: this.skuStock.categoryId,
        warehouseId: this.skuStock.warehouseId,
        name: this.skuStock.name,
        startTime: this.logSearch.startTime,
        endTime: this.logSearch.endTime,
        skuId: this.skuStock.skuId,
      }
      StockLogApi.getLogPages(searchData)
        .then(response => {
          const { data } = response.data
          const pageData = data.data

          this.stockLogs = pageData.records
          if (pageData.size === -1) {
            this.logPages.serverItemsLength = this.stockLogs.length
          } else {
            this.logPages.serverItemsLength = pageData.total
          }

          this.skuStock.productName = data.sku.productName
          this.skuStock.title = data.sku.title
          this.$emit('getNextAndPreSku', { prefixSkuId: data.prefixSkuId, suffixSkuId: data.suffixSkuId })
        })
    },
    toLogSearch() {
      this.loadLogPage({ page: 1, itemsPerPage: this.logPages.itemsPerPage })
    },
    resetLogSearch() {
      this.logSearch.startTime = ''
      this.logSearch.endTime = ''
      this.toLogSearch()
    },
  },
}
</script>

<style scoped>

</style>
