import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/product/material/bill'
export default {

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  toggleMaterialBillStatus(id, status, handleUserId) {
    return axios.post(`${prefixPath}/toggleMaterialBillStatus`, qs.stringify({ id, status, handleUserId }))
  },

  getDetail(data) {
    return axios.post(`${prefixPath}/getDetail`, data)
  },

  loadData(data) {
    return axios.post(`${prefixPath}/loadData`, data)
  },

  createMaterialBill(data) {
    return axios.post(`${prefixPath}/createMaterialBill`, data)
  },

  /**
   * 增加领料单打印次数
   * @param {*} materialId //单号id
   */
  addMaterialPrintCount(materialId) {
    return axios.post(`${prefixPath}/addMaterialPrintCount`, qs.stringify({
      materialId,
    }))
  },

}
