var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('p',{staticClass:"text-h6"},[_vm._v(" 基本信息 ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_vm._v(" 领料人(客户)："+_vm._s(_vm.recordDetail.baseInfo.detail.takeUserName ? _vm.recordDetail.baseInfo.detail.takeUserName : _vm.recordDetail.baseInfo.detail.sysTakeUserName)+" ")]),_c('v-col',{attrs:{"md":"4"}},[_vm._v(" 申请时间："+_vm._s(_vm.recordDetail.baseInfo.detail.createdTime)+" ")]),_c('v-col',{attrs:{"md":"4"}},[_vm._v(" 领料单号："+_vm._s(_vm.recordDetail.baseInfo.detail.id)+" ")]),_c('v-col',{attrs:{"md":"4"}},[_vm._v(" 领料状态： "),_c('v-chip',{staticClass:"font-weight-semibold text-capitalize",attrs:{"color":_vm.getOrderStatusColor(_vm.recordDetail.baseInfo.detail.status),"small":""}},[_vm._v(" "+_vm._s(_vm.getOrderStatusByValue(_vm.recordDetail.baseInfo.detail.status))+" ")])],1),_c('v-col',{attrs:{"md":"4"}},[_vm._v(" 审核人："+_vm._s(_vm.recordDetail.baseInfo.detail.handleUserName)+" ")]),_c('v-col',{attrs:{"md":"4"}},[_vm._v(" 审核时间："+_vm._s(_vm.recordDetail.baseInfo.detail.handledTime)+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_vm._v(" 领料原因："+_vm._s(_vm.recordDetail.baseInfo.detail.remark)+" ")])],1)],1)],1),_c('v-card',{staticClass:"mt-10"},[_c('v-card-title',[_c('p',{staticClass:"text-h6"},[_vm._v(" 领料详情 ")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":{
          itemsPerPageAllText: '全部',
          itemsPerPageText: '每页显示 ',
          showCurrentPage: true
        },"disable-filtering":"","headers":_vm.getSkuHeader(),"items":_vm.recordDetails,"items-per-page":_vm.detailPages.itemsPerPage,"server-items-length":_vm.detailPages.serverItemsLength,"no-data-text":"无数据"},on:{"pagination":_vm.loadDetailPage},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
        var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-avatar',{attrs:{"size":"33"},on:{"click":function($event){$event.stopPropagation();return _vm.previewImg(item.skuProductImg)}}},[_c('img',{attrs:{"src":item.skuProductImg,"alt":"John"}})])],1),_c('v-col',{staticClass:"pl-0"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.productName)+" ")]),_c('br'),(!item.isSingle)?_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.title))]):_vm._e()])],1)]}},{key:"item.categoryName",fn:function(ref){
        var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"pl-0"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.categoryName)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.warehouseName))])])],1)]}},{key:"item.totalQuantity",fn:function(ref){
        var item = ref.item;
return [(!_vm.recordDetail.baseInfo.isIn)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.convertToBoxAndRemainder(item.totalQuantity, item)))]):_c('span',{staticStyle:{"color":"rgb(95, 157, 3)"}},[_vm._v(_vm._s(_vm.convertToBoxAndRemainder(item.totalQuantity, item)))])]}},{key:"item.stockNum",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertToBoxAndRemainder(item.stockNum, item)))])]}},{key:"item.quantity",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertToBoxAndRemainder(item.quantity, item)))])]}},{key:"item.unit",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"70px"},attrs:{"id":"unitId","dense":"","hide-details":"","item-text":"unitName","item-value":"id","items":item.unitConversion},model:{value:(item.unitId),callback:function ($$v) {_vm.$set(item, "unitId", $$v)},expression:"item.unitId"}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","dense":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.showStockLog(item.skuId)}}},[_vm._v(" 查看库存日志 ")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.previewImgDialog),callback:function ($$v) {_vm.previewImgDialog=$$v},expression:"previewImgDialog"}},[_c('v-card',[_c('v-card-text',[_c('v-img',{attrs:{"src":_vm.previewImgUrl || require('@/assets/images/white.jpeg'),"max-width":"500"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }