<template>
  <div>
    <v-card>
      <v-card-title>
        <p class="text-h6">
          基本信息
        </p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="4">
            领料人(客户)：{{ recordDetail.baseInfo.detail.takeUserName ? recordDetail.baseInfo.detail.takeUserName : recordDetail.baseInfo.detail.sysTakeUserName }}
          </v-col>
          <v-col md="4">
            申请时间：{{ recordDetail.baseInfo.detail.createdTime }}
          </v-col>
          <v-col md="4">
            领料单号：{{ recordDetail.baseInfo.detail.id }}
          </v-col>

          <v-col
            md="4"
          >
            领料状态：
            <v-chip

              :color="getOrderStatusColor(recordDetail.baseInfo.detail.status)"
              small
              class="font-weight-semibold text-capitalize"
            >
              {{ getOrderStatusByValue(recordDetail.baseInfo.detail.status) }}
            </v-chip>
          </v-col>
          <v-col md="4">
            审核人：{{ recordDetail.baseInfo.detail.handleUserName }}
          </v-col>
          <v-col md="4">
            审核时间：{{ recordDetail.baseInfo.detail.handledTime }}
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            领料原因：{{ recordDetail.baseInfo.detail.remark }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mt-10"
    >
      <v-card-title>
        <p class="text-h6">
          领料详情
        </p>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          disable-filtering
          :headers="getSkuHeader()"
          :items="recordDetails"
          :items-per-page="detailPages.itemsPerPage"
          :server-items-length="detailPages.serverItemsLength"
          no-data-text="无数据"
          @pagination="loadDetailPage"
        >
          <template #[`item.productName`]="{ item }">
            <v-row>
              <v-col cols="auto">
                <v-avatar
                  size="33"
                  @click.stop="previewImg(item.skuProductImg)"
                >
                  <img
                    :src="item.skuProductImg"
                    alt="John"
                  >
                </v-avatar>
              </v-col>
              <v-col class="pl-0">
                <span class="text-subtitle-2">{{ item.productName }}  </span><br>
                <span
                  v-if="!item.isSingle"
                  class="text-caption"
                >{{ item.title }}</span>
              </v-col>
            </v-row>
          </template>
          <template #[`item.categoryName`]="{ item }">
            <v-row>
              <v-col class="pl-0">
                <span class="text-subtitle-2">{{ item.categoryName }}  </span><br>
                <span
                  class="text-caption"
                >{{ item.warehouseName }}</span>
              </v-col>
            </v-row>
          </template>
          <template #[`item.totalQuantity`]="{ item }">
            <span
              v-if="!recordDetail.baseInfo.isIn"
              style="color: red"
            >{{ convertToBoxAndRemainder(item.totalQuantity, item) }}</span>
            <span
              v-else
              style="color: rgb(95, 157, 3)"
            >{{ convertToBoxAndRemainder(item.totalQuantity, item) }}</span>
          </template>

          <template #[`item.stockNum`]="{ item }">
            <span>{{ convertToBoxAndRemainder(item.stockNum, item) }}</span>
          </template>

          <template #[`item.quantity`]="{ item }">
            <span>{{ convertToBoxAndRemainder(item.quantity, item) }}</span>
          </template>
          <template #[`item.unit`]="{ item }">
            <v-select
              id="unitId"
              v-model="item.unitId"
              dense
              style="width: 70px;"
              hide-details
              item-text="unitName"
              item-value="id"
              :items="item.unitConversion"
            ></v-select>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              text
              dense
              x-small
              color="primary"
              @click="showStockLog(item.skuId)"
            >
              查看库存日志
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="previewImgDialog"
      max-width="500"
    >
      <v-card>
        <v-card-text>
          <v-img
            :src="previewImgUrl || require('@/assets/images/white.jpeg')"
            max-width="500"
          ></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MaterialBillApi from '@/api/product/materialBill'

// import Log from '@/components/Log'

export default {
  name: 'BillDetail',
  components: {
    // Log,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    log: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recordDetail: {
        baseInfo: {
          detail: {},
        },
        data: {},
      },
      recordDetails: [],
      detailPages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      statusList: [
        { text: '待审核', color: 'danger', value: 0 },
        { text: '待处理', color: 'warning', value: 3 },
        { text: '已完成', color: 'success', value: 1 },
        { text: '已拒绝', color: '', value: 2 },
      ],
      previewImgUrl: '',
      previewImgDialog: false,
    }
  },
  methods: {
    getSkuHeader() {
      const baseHeader = [
        // { text: 'ID', sortable: false, value: 'skuId' },
        { text: '商品名称', value: 'productName' },
        { text: '所属分类/仓库', value: 'categoryName' },
        { text: '包装规格', value: 'unit' },
        { text: '总库存', value: 'stockNum' },
        { text: '领料数量', value: 'quantity' },
        { text: '出库数量', value: 'totalQuantity' },
      ]
      if (this.log) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    loadDetailPage({ page, itemsPerPage }) {
      if (!this.detail.id) {
        return
      }
      const searchData = {
        rows: itemsPerPage,
        page,
        id: this.detail.id,
        status: this.detail.status,
        searchValue: this.detail.searchValue,
      }
      MaterialBillApi.getDetail(searchData).then(res => {
        this.recordDetail = res.data.data
        this.recordDetails = this.recordDetail.data.records
        if (this.recordDetail.data.size === -1) {
          this.detailPages.serverItemsLength = this.recordDetails.length
        } else {
          this.detailPages.serverItemsLength = this.recordDetail.data.total
        }
        this.$emit('getNextAndPreSku', {
          prefixId: this.recordDetail.baseInfo.prefixId,
          suffixId: this.recordDetail.baseInfo.suffixId,
        })
        this.$emit('getDetail', this.recordDetail)
      })
    },
    showStockLog(skuId) {
      this.$emit('showLog', skuId)
    },
    getOrderStatusByValue(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.text : ''
    },
    getOrderStatusColor(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.color : ''
    },
    previewImg(url) {
      this.previewImgUrl = url
      this.previewImgDialog = true
    },
    convertToBoxAndRemainder(totalQuantity, item) {
      const { unitId } = item
      const { unitConversion } = item

      // 根据unitId找到对应的单位
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)

      // 如果isBase为true，则直接返回基准单位的数量
      if (selectedUnit.isBase) {
        return `${totalQuantity}${selectedUnit.unitName}`
      }

      // 计算整箱数和尾数
      const boxes = Math.floor(totalQuantity / selectedUnit.unitQuantity)
      const remainder = totalQuantity % selectedUnit.unitQuantity

      // 构建结果字符串
      let result = ''

      // 如果没有整箱，则只显示尾数
      if (boxes === 0) {
        result = `0箱+${remainder}个尾数`
      } else {
        // 否则显示箱数和尾数
        result = `${boxes}箱`

        // 如果有尾数，则添加尾数部分
        if (remainder > 0) {
          result += `+${remainder}个尾数`
        }
      }

      return result
    },
    changeUnit(item) {
      const { unitId } = item
      const { unitConversion } = item
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)
      if (this.model === 0) {
        item.totalQuantity = parseInt(item.stockNum, 0) - parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)
      } else {
        item.totalQuantity = parseInt(item.stockNum, 0) + parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)
      }
      const record = this.records.find(record => record.skuId === item.skuId)
      if (record) {
        record.quantity = item.quantity
      } else {
        this.records.push(item)
        this.recordQuantity += 1
      }
    },
  },
}
</script>

<style scoped>

</style>
