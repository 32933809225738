var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-care-title',[_c('p',{staticClass:"text-h6 pa-5"},[_vm._v(" "+_vm._s(_vm.skuStock.productName)+" "+_vm._s(_vm.skuStock.title !== null ? '/' :'')+" "+_vm._s(_vm.skuStock.title)+" ")])]),_c('v-card-text',[_c('v-form',{ref:"skuLogForm"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row-reverse pb-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"me-3",on:{"click":function($event){return _vm.resetLogSearch()}}},[_c('span',[_vm._v("重置")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.toLogSearch()}}},[_c('span',[_vm._v("查询")])])],1),(_vm.$can('product_list_search', 'product'))?_c('div',{staticClass:"d-flex align-center pt-6 mr-5 pb-0"},[_c('DateTimePick',{attrs:{"label":"结束时间"},model:{value:(_vm.logSearch.endTime),callback:function ($$v) {_vm.$set(_vm.logSearch, "endTime", $$v)},expression:"logSearch.endTime"}})],1):_vm._e(),(_vm.$can('product_list_search', 'product'))?_c('div',{staticClass:"d-flex align-center pt-6 mr-5 pb-0"},[_c('DateTimePick',{attrs:{"label":"开始时间"},model:{value:(_vm.logSearch.startTime),callback:function ($$v) {_vm.$set(_vm.logSearch, "startTime", $$v)},expression:"logSearch.startTime"}})],1):_vm._e()])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"footer-props":{
              itemsPerPageAllText: '全部',
              itemsPerPageText: '每页显示 ',
              showCurrentPage: true
            },"disable-filtering":"","headers":_vm.getLogHeader(),"items":_vm.stockLogs,"items-per-page":_vm.logPages.itemsPerPage,"server-items-length":_vm.logPages.serverItemsLength,"no-data-text":"无数据"},on:{"pagination":_vm.loadLogPage},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
            var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-avatar',{attrs:{"size":"33"}},[_c('img',{attrs:{"src":item.productImg,"alt":"John"}})])],1),_c('v-col',{staticClass:"pl-0"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.productName)+" ")]),_c('br'),(!item.isSingle)?_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.skuName))]):_vm._e()])],1)]}},{key:"item.categoryName",fn:function(ref){
            var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"pl-0"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.categoryName)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.warehouseName))])])],1)]}},{key:"item.typeName",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"color":"blueviolet"}},[_vm._v(_vm._s(item.typeName))])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }