<template>
  <v-container>
    <v-menu
      v-model="isMenuOpen"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="datetime"
          outlined
          dense
          :label="label"
          v-bind="attrs"
          :hide-details="hide"
          v-on="on"
        ></v-text-field>
      </template>
      <v-card>
        <v-card-text
          class="primary"
        >
          <h2 style="color: white !important">
            日期: {{ datePicker }}
          </h2>
          <h2 style="color: white !important">
            时间: {{ timePicker }}
          </h2>
        </v-card-text>
        <v-card-text>
          <v-date-picker
            v-model="datePicker"
            color="primary"
            locale="zh-CN"
            ampm-in-title
            no-title
          ></v-date-picker>
          <v-time-picker
            v-model="timePicker"
            locale="zh-CN"
            color="primary"
          ></v-time-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="handleDone"
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  // props: ['value', 'label', 'hide'],
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    datePicker: '',
    timePicker: '',
    isMenuOpen: false,
    reload: true,
  }),

  computed: {
    datetime: {
      get() {
        if (this.datePicker && this.timePicker) {
          return `${this.datePicker} ${this.timePicker}`
        }

        return ''
      },
      set(value) {
        const [date, time] = value.split(' ')
        this.datePicker = date
        this.timePicker = time
      },
    },
  },

  watch: {
    value(newValue) {
      if (newValue) {
        const [date, time] = newValue.split(' ')
        this.datePicker = date
        this.timePicker = time
      } else {
        this.datePicker = ''
        this.timePicker = ''
      }
    },
  },

  methods: {
    handleDone() {
      const datetime = `${this.datePicker} ${this.timePicker}`
      this.$emit('input', datetime)
      this.isMenuOpen = false
    },
  },
}
</script>
