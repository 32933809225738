import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/product/stock/log'
export default {

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  getLogPages(data) {
    return axios.post(`${prefixPath}/getLogPages`, data)
  },

  loadData() {
    return axios.get(`${prefixPath}/loadData`)
  },

}
